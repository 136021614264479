//Liquidity Page

import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import "../css/products.css";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import clyLiquidity from "../lottie/CLY-Liquidity.json";

function Liquidity() {
  // Lottie player settins
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768); // Adjust the breakpoint according to your design
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="content">
      <section className="liquidity container d-flex f-align-center">
        <div className="hero d-flex f-align-center mobile-f-col-rev">
          <div className="w-50 d-flex f-align-start f-dir-col">
            <h1 className="color-white mt-0 mb-0">
              Liquidity Provision Program
            </h1>
            <h3 className="text-gradient medium-title">
              Unlock special rewards from Colony's DeFi Avalanche liquidity
              positions.
            </h3>
            <p>
              As an Avalanche ecosystem accelerator, Colony stands dedicated to
              bolstering the DeFi initiatives taking root on Avalanche. In
              alignment with this objective, the Liquidity Provision Program has
              been designed to offer protocols the liquidity boost required to
              amplify their TVL. By providing this essential liquidity, Colony
              not only supports the protocols but also generates yield from the
              lent liquidity. The resulting rewards are distributed among $CLY
              stakers.
            </p>
            <Link
              to="https://app.colonylab.io/airdrops"
              target="_blank"
              rel="noopener noreferrer"
              className="btn"
            >
              Access Liquidity Provision Airdrops
            </Link>
          </div>
          <div className="w-50 d-flex f-justify-center f-align-center">
            <Player autoplay={isDesktop} loop src={clyLiquidity}>
              <Controls visible={false} />
            </Player>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Liquidity;
