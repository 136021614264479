import React from "react";
import { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import "./css/layout.css";
import "./css/ui.css";
import "./css/loading.css";
import "./css/icons.css";
import "./css/style.css";
import "./css/animations.css";
import "./css/responsive.css";
import { Navbar } from "./components/Navbar";
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./components/Footer.js";
import Home from "./pages/Home.js";
import Community from "./pages/Community";
import Products from "./pages/Products";
import Projects from "./pages/Projects";
import EarlyStage from "./pages/Early-Stage-Program";
import Validator from "./pages/Validator-Program";
import Cai from "./pages/Colony-Avalanche-Index";
import Liquidity from "./pages/Liquidity-Provision-Program";
import backVid from "./video/background.mp4";
import Loading from "./components/Loading";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate data loading with a timeout
    const fetchData = async () => {
      // Simulate an API call
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setLoading(false); // Data has been loaded, hide the loading screen
    };

    fetchData();
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="main">
          <ScrollToTop />
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/community" element={<Community />} />
            <Route path="/products" element={<Products />} />
            <Route path="/early-stage-program" element={<EarlyStage />} />
            <Route path="/validator-program" element={<Validator />} />
            <Route path="/colony-avalanche-index" element={<Cai />} />
            <Route
              path="/liquidity-provision-program"
              element={<Liquidity />}
            />
            <Route path="/projects" element={<Projects />} />
          </Routes>
          <Footer />
          <video autoPlay loop muted className="bg-vid">
            <source src={backVid} type="video/mp4" />
          </video>
        </div>
      )}
    </div>
  );
}

export default App;
