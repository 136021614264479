//Projects Page

import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import ScrollButton from "../components/ScrollButton";

import "../css/projects.css";
import clyBuilder from "../lottie/CLY-Builder.json";
import iconApply from "../images/icon-apply.svg";
import iconContact from "../images/icon-contact.svg";

function Projects() {
  // Lottie player settins
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768); // Adjust the breakpoint according to your design
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="content">
      <section className="projects container d-flex f-align-center">
        <div className="hero d-flex f-align-center mobile-f-col-rev">
          <div className="w-50 d-flex f-align-start f-dir-col">
            <h2 className="color-white mt-0 mb-0">
              <span className="text-gradient medium-title">
                Onboard a community of{" "}
              </span>{" "}
              <br />
              thousands of investors and future users.
            </h2>
            <p>
              Designed by builders for builders, Colony Lab's 'Early-Stage
              Program' offers an efficient Decentralized Fundraising platform.
              Decentralize your capitalization tables, mitigate selling
              pressure, all while gaining a vibrant community of investors and
              future users right from the outset. The perfect alignment of
              interests sets the stage for a bright future for your project.
            </p>
            <p>
              And that's not all - Colony goes above and beyond, offering
              comprehensive support across tokenomics, business development,
              marketing, community, and legal expertise.
            </p>
            <ScrollButton targetSectionId="section1" />
          </div>
          <div className="w-50 d-flex f-justify-center f-align-center">
            <Player autoplay={isDesktop} loop src={clyBuilder}>
              <Controls visible={false} />
            </Player>
          </div>
        </div>
      </section>

      <section className="apply container d-flex f-align-center" id="section1">
        <div className="d-flex container f-align-center f-justify-between">
          <div className="apply-card">
            <h4>Apply for Fundraise</h4>
            <div className="divider"></div>
            <img
              src={iconApply}
              title="Apply for Fundraise"
              alt="Apply for Fundraise"
            />
            <p>Looking to fundraise for your seed/private sale? Apply now.</p>
            <Link
              to="https://forms.monday.com/forms/55c77c179ea135147b51cb5057cdab38?r=use1"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-light"
            >
              Application form
            </Link>
          </div>

          <div className="apply-card">
            <h4>Get in Touch for Colony Services</h4>
            <div className="divider"></div>
            <img src={iconContact} title="Get in Touch" alt="Get in Touch" />
            <p>
              Need help with tokenomics, tokenization, growth and marketing,
              legal matters, and more? Contact us for more details about our
              services.
            </p>
            <Link
              to="https://forms.monday.com/forms/1e937b32e658c4d0851fd1074ee84b3e?r=use1"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-light"
            >
              Contact form
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Projects;
