//Community Page

import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import "../css/community.css";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import ScrollButton from "../components/ScrollButton";

import clyCommunity from "../lottie/CLY-Community.json";
import iconGet from "../images/icon-get.png";
import iconStake from "../images/icon-stake.png";
import iconAccess from "../images/icon-access.png";
import iconLearn from "../images/icon-learn.png";
import iconDeepDive from "../images/icon-deep-dive.png";
import iconDemo from "../images/icon-demo.png";
import iconLatest from "../images/icon-latest.png";

function Community() {
  // Lottie player settins
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768); // Adjust the breakpoint according to your design
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="content">
      <section className="community container d-flex f-align-center">
        <div className="d-flex f-align-center mobile-f-col-rev">
          <div className="w-50 d-flex f-align-start f-dir-col">
            <h2 className="color-white mt-0 mb-0">
              Discover multiple Avalanche opportunities with{" "}
              <span className="text-gradient">$CLY!</span>{" "}
            </h2>
            <p>
              $CLY offers its community unparalleled utility, providing a unique
              and diversified exposure to Avalanche through a single token.
              Stake $CLY to invest in seed/private sales of early-stage
              projects, receive airdrops, and earn AVAX rewards through Colony's
              validator nodes. Enjoy Colony's $CAI fee revenues and more.
            </p>
            <Link
              to="/early-stage-program"
              rel="noopener noreferrer"
              className="btn"
            >
              Explore products
            </Link>
            <ScrollButton targetSectionId="section1" />
          </div>
          <div className="w-50">
            <Player autoplay={isDesktop} loop src={clyCommunity}>
              <Controls visible={false} />
            </Player>
          </div>
        </div>
      </section>

      <section
        className="unlock container d-flex f-align-center f-justify-center"
        id="section1"
      >
        <div className="container d-flex f-dir-col f-align-center">
          <h2 className="color-white mt-0 mb-0 text-center">
            <span className="text-gradient">Unlock your $CLY</span>
            <br />
            Potential today
          </h2>
          <div className="card-container">
            <div className="card-simple">
              <img
                src={iconGet}
                title="Potential today"
                alt="Potential today"
              />
              <h5>Get $CLY</h5>
              <Link
                to="https://traderjoexyz.com/avalanche/trade?inputCurrency=AVAX&outputCurrency=0xec3492a2508ddf4fdc0cd76f31f340b30d1793e6"
                target="_blank"
                rel="noopener noreferrer"
                className="arrow-button"
              >
                <span className="icon-ar-angle"></span>
              </Link>
            </div>

            <div className="card-simple">
              <img src={iconStake} title="Stake $CLY" alt="Stake $CLY" />
              <h5>Stake $CLY</h5>
              <Link
                to="https://app.colonylab.io/stake"
                target="_blank"
                rel="noopener noreferrer"
                className="arrow-button"
              >
                <span className="icon-ar-angle"></span>
              </Link>
            </div>

            <div className="card-simple">
              <img
                src={iconAccess}
                title="Access Products & Rewards"
                alt="Access Products & Rewards"
              />
              <h5>Access Products & Rewards</h5>
              <Link
                to="https://app.colonylab.io/airdrops"
                target="_blank"
                rel="noopener noreferrer"
                className="arrow-button"
              >
                <span className="icon-ar-angle"></span>
              </Link>
            </div>
          </div>

          <Link
            to="https://snowtrace.io/token/0xec3492a2508ddf4fdc0cd76f31f340b30d1793e6"
            target="_blank"
            rel="noopener noreferrer"
            className="btn"
          >
            $CLY TOKEN ADDRESS
          </Link>
        </div>
      </section>

      <section className="speed container d-flex f-align-center f-dir-col f-justify-center">
        <div className="container d-flex f-dir-col f-align-center">
          <h2 className="color-white mt-0 mb-0 text-center">
            Get up to <span className="text-gradient">speed fast</span>
          </h2>
          <div className="card-container d-flex">
            <div className="card-simple">
              <img
                src={iconLearn}
                title="Learn How to Stake $CLY to Access Products & Rewards"
                className="small-icon"
                alt="Learn How to Stake $CLY to Access Products & Rewards"
              />
              <p>Learn How to Stake $CLY to Access Products & Rewards.</p>
              <Link
                to="https://medium.com/@Colonylab/colonys-staking-tutorial-28fa54d27de6 "
                target="_blank"
                rel="noopener noreferrer"
                className="arrow-button"
              >
                <span className="icon-ar-angle"></span>
              </Link>
            </div>

            <div className="card-simple">
              <img
                src={iconDeepDive}
                title="Dive Deep into Colony Lab Products"
                className="small-icon"
                alt="Dive Deep into Colony Lab Products"
              />
              <p>
                Dive Deep into Colony Lab Products. (Gitbook, Step-by-Step
                Guides)
              </p>
              <Link
                to="https://docs.colonylab.io/"
                target="_blank"
                rel="noopener noreferrer"
                className="arrow-button"
              >
                <span className="icon-ar-angle"></span>
              </Link>
            </div>

            <div className="card-simple">
              <img
                src={iconDemo}
                title="Watch a Demo"
                className="small-icon"
                alt="Watch a Demo"
              />
              <p>Watch a Demo of How to Invest in Seed/Private sales.</p>

              <Link
                to="https://www.youtube.com/watch?v=TduFyXLva14&ab_channel=ColonyLab"
                target="_blank"
                rel="noopener noreferrer"
                className="arrow-button"
              >
                <span className="icon-ar-angle"></span>
              </Link>
            </div>

            <div className="card-simple">
              <img
                src={iconLatest}
                title="Stay Up-to-Date with Our Latest Announcements"
                className="small-icon"
                alt="Stay Up-to-Date with Our Latest Announcements"
              />
              <p>Stay Up-to-Date with Our Latest Announcements.</p>

              <Link
                to="https://twitter.com/Colonylab"
                target="_blank"
                rel="noopener noreferrer"
                className="arrow-button"
              >
                <span className="icon-ar-angle"></span>
              </Link>
            </div>
          </div>
        </div>

        <div className="dash-div">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1157"
            height="2"
            viewBox="0 0 1157 2"
            fill="none"
          >
            <path
              d="M1 1H1156"
              stroke="#2F287B"
              strokeLinecap="round"
              strokeDasharray="2 10"
            />
          </svg>
        </div>

        <div className="join container d-flex f-dir-col f-align-center">
          <h3>
            TOGETHER IS BETTER.{" "}
            <span className="text-gradient">JOIN OUR COMMUNITY</span>
          </h3>
          <div className="social-bar">
            <Link to="https://twitter.com/Colonylab" target="_blank">
              <span className="icon-twitter"></span>
              Twitter
            </Link>
            <div className="div-vertical"></div>
            <Link to="https://www.youtube.com/@ColonyLab" target="_blank">
              <span className="icon-youtube"></span>
              Youtube
            </Link>
            <div className="div-vertical"></div>
            <Link
              to="https://www.linkedin.com/company/colonylab/"
              target="_blank"
            >
              <span className="icon-linkedin"></span>
              Linkedin
            </Link>
            <div className="div-vertical"></div>
            <Link to="https://medium.com/colony-lab" target="_blank">
              <span className="icon-medium"></span>
              Medium
            </Link>
            <div className="div-vertical"></div>
            <Link to="https://t.me/COLONY_Announcement" target="_blank">
              <span className="icon-telegram"></span>
              Telegram
            </Link>
            <div className="div-vertical"></div>
            <Link to="https://bit.ly/colonylab-discord" target="_blank">
              <span className="icon-discord"></span>
              Discord
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Community;
