//Product Page

import React from "react";
import "../css/products.css";

function Products() {
  return (
    <div className="main">
      <section className="container d-flex f-align-center">
        <div className="d-flex f-align-center">
          <div className="w-50">
            <h1 className="color-white mt-0 mb-0">PRODUCTS PAGE</h1>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Products;
