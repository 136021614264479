import React from "react";
import { Link } from "react-router-dom";

const ScrollButton = ({ targetSectionId }) => {
  const handleClick = () => {
    const section = document.getElementById(targetSectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Link className="arrow-button scroll-button" onClick={handleClick}>
      <span className="icon-ar-down"></span>
    </Link>
  );
};

export default ScrollButton;
