//Early-Stage Page

import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ScrollButton from "../components/ScrollButton";

import "../css/products.css";
import appScreen1 from "../images/app-screen-1.jpg";
import appScreen2 from "../images/app-screen-2.jpg";
import appSlide1 from "../images/screen-slide-1.jpg";
import appSlide2 from "../images/screen-slide-2.jpg";
import appSlide3 from "../images/screen-slide-3.jpg";
import appSlide4 from "../images/screen-slide-4.jpg";

function Early() {
  var settings = {
    centerMode: true,
    centerPadding: "20%",
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="content">
      <section className="early container d-flex f-align-center">
        <div className="hero d-flex f-justify-between mobile-f-col-rev">
          <div className="w-50 d-flex f-align-start f-dir-col">
            <h1 className="color-white mt-0 mb-0">Early-Stage Program</h1>
            <h3 className="text-gradient medium-title">
              Seed and Private Sales Access
            </h3>
            <p>
              Colony's Early-stage program provides CLY stakers access to invest
              in early-stage Avalanche projects before IDOs through Seed and
              Private sales. This once-exclusive opportunity for VCs and angel
              investors is now available to you.
            </p>
            <p>
              Explore early-stage Avalanche projects, upvote, comment, rate, and
              access project scores generated from thousands of community
              analyses. Invest in seed and private sales for the first time and
              trade your locked/vesting tokens freely, thanks to our Liquid
              Vesting solution.
            </p>
            <div className="btn-container d-flex">
              <Link
                to="https://docs.colonylab.io/early-stage-program/introducing-early-stage-program-and-liquid-vesting"
                target="_blank"
                rel="noopener noreferrer"
                className="btn mr-20"
              >
                Learn all about early stage
              </Link>
              <Link
                to="https://app.colonylab.io/early-stage/deal-flow"
                target="_blank"
                rel="noopener noreferrer"
                className="btn"
              >
                Access Early Stage Platform
              </Link>
            </div>
            <ScrollButton targetSectionId="section1" />
          </div>
          <div className="w-40 product-feature d-flex f-justify-center f-align-center">
            <img
              src={appScreen1}
              title="Colony Application Screen"
              className="product-img-1"
              alt="Colony Application Screen"
            />
            <img
              src={appScreen2}
              title="Colony Application Screen"
              className="product-img-2"
              alt="Colony Application Screen"
            />
          </div>
        </div>
      </section>

      <section
        className="liquid container d-flex f-align-center f-dir-col"
        id="section1"
      >
        <div className="d-flex container-wide f-align-end f-justify-between mobile-f-col">
          <div className="w-50">
            <h1 className="color-white mt-0 mb-0">Liquid Vesting</h1>
            <h3 className="text-gradient medium-title">
              Shaping a Liquid Future for Primary Markets
            </h3>

            <div className="btn-container d-flex">
              <Link
                to="https://docs.colonylab.io/early-stage-program/core-concepts-explanation/liquid-vesting"
                target="_blank"
                rel="noopener noreferrer"
                className="btn mr-20"
              >
                Learn all about liquid vesting
              </Link>
              <Link
                to="https://app.colonylab.io/exchange/trade"
                target="_blank"
                rel="noopener noreferrer"
                className="btn mr-20"
              >
                Access colony’s dex
              </Link>
            </div>
          </div>
          <div className="w-50">
            <p className="text-large">
              Seed and private sales allocations often come with extended
              lockups and vesting schedules, in exchange to access to tokens at
              a low price. With Colony's Liquid Vesting, early project investors
              can now securely trade their locked/vesting tokens on our AMM
              (Colony's DEX) without limitations. A new era of flexibility and
              liquidity on the primary market has been unlocked.
            </p>
          </div>
        </div>

        <div className="slider">
          <Slider {...settings}>
            <img
              src={appSlide1}
              title="Colony DEX Screen"
              alt="Colony DEX Screen"
            />
            <img
              src={appSlide2}
              title="Colony DEX Screen"
              alt="Colony DEX Screen"
            />
            <img
              src={appSlide3}
              title="Colony DEX Screen"
              alt="Colony DEX Screen"
            />
            <img
              src={appSlide4}
              title="Colony DEX Screen"
              alt="Colony DEX Screen"
            />
          </Slider>
        </div>
      </section>
    </div>
  );
}

export default Early;
