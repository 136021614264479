//Homepage

import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import homeVid from "../lottie/CLY-Hero-Gogo.json";
import missionVid from "../lottie/CLY-Mission.json";
import ScrollButton from "../components/ScrollButton";
import "../css/home.css";

import iconSeed from "../images/icon-seed.png";
import iconReward from "../images/icon-reward.png";
import iconRewards from "../images/icon-rewards.png";
import iconCai from "../images/icon-cai.png";
import iconTailored from "../images/icon-tailored.png";
import iconFundraising from "../images/icon-fundraising.png";
import iconPrelaunch from "../images/icon-pre-launch.png";
import iconEngagement from "../images/icon-engagement.png";
import iconTransparent from "../images/icon-transparent.png";
import iconUser from "../images/icon-user.png";
import iconComplience from "../images/icon-complience.png";
import iconLiquid from "../images/icon-liquid.png";
import elie from "../images/elie.png";
import wessal from "../images/wessal.png";
import gregorz from "../images/gregorz.png";
import aurelien from "../images/aurelien.png";
import partner1 from "../images/backed-avalabs.png";
import partner2 from "../images/backed-shima.png";
import partner3 from "../images/backed-gbv.png";
import partner4 from "../images/backed-hashkey.png";
import partner5 from "../images/backed-bixin.png";
import comLarge from "../images/community.png";
import builders from "../images/builders.png";

function Home() {
  var platform = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick",
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          centerMode: true,
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  // const [sections, setSections] = useState([]);
  // const [loaded, setLoaded] = useState(false);

  // useEffect(() => {
  //   const activateSections = () => {
  //     const windowScrollTop = window.scrollY;
  //     const windowHeight = window.innerHeight;

  //     sections.forEach((section) => {
  //       const sectionTop = section.offsetTop;
  //       if (loaded && sectionTop - windowScrollTop < windowHeight / 1) {
  //         section.classList.add("active");
  //       }
  //     });
  //   };

  //   const handleScroll = () => {
  //     activateSections();
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [loaded, sections]);

  // useEffect(() => {

  //   const sectionElements = document.querySelectorAll(
  //     ".content section:not(.active)"
  //   );
  //   setSections(Array.from(sectionElements));
  //   const timeout = setTimeout(() => {
  //     setLoaded(true);
  //   }, 2000);

  //   return () => clearTimeout(timeout);
  // }, []);

  // Lottie player settins
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768); // Adjust the breakpoint according to your design
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="content">
      <section className="home hero container-wide d-flex f-align-center animate active">
        <div className="container d-flex p-relative f-align-center mobile-f-col-rev animate-elem animate-fadeIn animate-delay-04">
          <div className="w-40">
            <h1 className="color-white mt-0 mb-0">
              THE <span className="underline">PREMIER</span> AVALANCHE ECOSYSTEM
              ACCELERATOR
            </h1>
            <p className="text-large">
              Empowering builders and communities alike
              <br /> – Discover limitless opportunities with
              <span className="text-gradient ml-5">Colony Lab</span> in
              Avalanche.
            </p>
            <ScrollButton targetSectionId="section1" />
          </div>
          <div className="w-70 home-vid">
            {" "}
            <Player loop autoplay={isDesktop} src={homeVid}>
              <Controls visible={false} />
            </Player>
          </div>
        </div>
      </section>

      <section
        className="mission container-wide d-flex f-align-center animate active"
        id="section1"
      >
        <div className="container-small d-flex f-align-center mobile-f-col">
          <div className="w-50 animate-elem animate-fadeIn animate-slideFromBottom p-relative animate-delay-02">
            <Player loop autoplay={isDesktop} src={missionVid}>
              <Controls visible={false} />
            </Player>
          </div>
          <div className="w-50 animate-elem animate-fadeIn animate-slideFromBottom p-relative animate-delay-04">
            <h3 className="">Our Dual Mission</h3>
            <p className="">
              Colony Lab is a driving force in accelerating the Avalanche
              ecosystem. We are more than a platform; we ignite progress. Our
              mission is twofold: we empower early-stage Avalanche projects and
              unlock unprecedented opportunities for the community.
              <br />
              <br />
              With us, builders and projects secure critical early capital,
              essential ecosystem support, organic early exposure, and a
              thriving community of investors and future users. We bring their
              visions to life. Simultaneously, the community gains access to
              unparalleled seed investment opportunities and a diverse array of
              yield-generating products.
            </p>
            <ScrollButton targetSectionId="section2" />
          </div>
        </div>
      </section>

      <section
        className="community container d-flex f-dir-col f-align-center animate active"
        id="section2"
      >
        <div className="outline animate-elem animate-fadeIn animate-slideFromBottom p-relative animate-delay-02">
          {" "}
          <img src={comLarge} title="Community" alt="Community" />
        </div>
        <div className="d-flex f-align-center f-justify-between w-100 mobile-f-col animate-elem animate-fadeIn animate-slideFromBottom p-relative animate-delay-02">
          <h3 className="w-50 animate-elem animate-fadeIn animate-slideFromBottom p-relative animate-delay-02">
            <span className="text-gradient ">
              One Platform,
              <br />{" "}
            </span>{" "}
            Multiple Opportunities
          </h3>
          <p className="w-40">
            Enjoy diversified exposure to Avalanche's growth on a single
            inclusive platform. Tailored for both passive income enthusiasts and
            active investors.
          </p>
        </div>
        <Slider
          {...platform}
          className="card-container d-flex animate-elem animate-fadeIn animate-slideFromBottom p-relative animate-delay-04"
        >
          <div className="card-holder">
            <div className="card">
              <div className="card-front">
                <h5>Seed/Private Sales Investments</h5>
                <img
                  src={iconSeed}
                  className="nav-logo"
                  title="Private Sales Investments"
                  alt="Private Sales Investments"
                />
                <div className="arrow-button">
                  <span className="icon-ar-right"></span>
                </div>
              </div>
              <div className="card-back">
                <h5>Seed/Private Sales Investments</h5>
                <p className="card-subtitle">Early-Stage Program: </p>
                <p>
                  Invest in seed/private sales of early-stage Avalanche projects
                </p>
                <a href="/early-stage-program" className="arrow-button">
                  <span className="icon-ar-angle"></span>
                </a>
              </div>
            </div>
          </div>

          <div className="card-holder">
            <div className="card">
              <div className="card-front">
                <h5>AVAX Rewards</h5>
                <img
                  src={iconReward}
                  className="nav-logo"
                  title="AVAX Rewards"
                  alt="AVAX Rewards"
                />
                <div className="arrow-button">
                  <span className="icon-ar-right"></span>
                </div>
              </div>
              <div className="card-back">
                <h5>AVAX Rewards</h5>
                <p className="card-subtitle">Validator Program:</p>
                <p>
                  Earn AVAX rewards via Colony's validator nodes securing the
                  blockchain
                </p>
                <a href="/validator-program" className="arrow-button">
                  <span className="icon-ar-angle"></span>
                </a>
              </div>
            </div>
          </div>

          <div className="card-holder">
            <div className="card">
              <div className="card-front">
                <h5>
                  <span className="text-gradient">$CAI</span> Avalanche's Top
                  Index
                </h5>
                <img
                  src={iconCai}
                  className="nav-logo"
                  title="Avalanche's Top
                Index"
                  alt="Avalanche's Top
                Index"
                />
                <div className="arrow-button">
                  <span className="icon-ar-right"></span>
                </div>
              </div>
              <div className="card-back">
                <h5>
                  <span className="text-gradient">$CAI</span> Avalanche's Top
                  Index
                </h5>
                <p className="card-subtitle">Colony Avalanche Index:</p>
                <p>
                  Experience hands-off passive investing with $CAI, Avalanche's
                  largest yield-bearing benchmark index
                </p>
                <a href="/colony-avalanche-index" className="arrow-button">
                  <span className="icon-ar-angle"></span>
                </a>
              </div>
            </div>
          </div>

          <div className="card-holder">
            <div className="card">
              <div className="card-front">
                <h5>Avalanche DeFi Rewards</h5>
                <img
                  src={iconRewards}
                  className="nav-logo"
                  title="Avalanche DeFi Rewards"
                  alt="Avalanche DeFi Rewards"
                />
                <div className="arrow-button">
                  <span className="icon-ar-right"></span>
                </div>
              </div>
              <div className="card-back">
                <h5>Avalanche DeFi Rewards</h5>
                <p className="card-subtitle">Liquidity Provision Program:</p>
                <p>
                  Unlock special rewards from Colony's Avalanche DeFi liquidity
                  positions
                </p>
                <a href="/liquidity-provision-program" className="arrow-button">
                  <span className="icon-ar-angle"></span>
                </a>
              </div>
            </div>
          </div>
        </Slider>
        <Link
          to="https://docs.colonylab.io/getting-started/benefits-of-joining-colony"
          target="_blank"
          rel="noopener noreferrer"
          className="btn animate-elem animate-fadeIn animate-slideFromBottom p-relative animate-delay-06"
        >
          Learn more about these products
        </Link>
      </section>

      <section className="builders container d-flex f-dir-col f-align-center animate active">
        <div className="outline animate-elem animate-fadeIn animate-slideFromBottom p-relative animate-delay-02">
          {" "}
          <img src={builders} title="Builders" alt="Builders" />
        </div>
        <div className="d-flex f-align-center f-justify-between w-100 mobile-f-col animate-elem animate-fadeIn animate-slideFromBottom p-relative animate-delay-04">
          <div className="w-50">
            <h3 className="m-0">
              <strong className="small-title">The Platform for</strong>
            </h3>
            <h3>
              Efficient{" "}
              <span className="text-gradient ml-5">Decentralized</span>
              <br />
              Fundraising
            </h3>
          </div>
          <p className="w-40">
            Empower your project's success with Colony Lab – the go-to platform
            for decentralized fundraising and community engagement, offering
            exclusive opportunities beyond what VCs can provide.
          </p>
        </div>
        <Slider
          {...platform}
          className="card-container d-flex animate-elem animate-fadeIn animate-slideFromBottom p-relative animate-delay-06"
        >
          <div className="card-holder">
            <div className="card">
              <div className="card-front">
                <h5>Tailored Launch Support</h5>
                <img
                  src={iconTailored}
                  className="nav-logo"
                  title="Tailored Launch Support"
                  alt="Tailored Launch Support"
                />
                <div className="arrow-button">
                  <span className="icon-ar-right"></span>
                </div>
              </div>
              <div className="card-back">
                <h5>Tailored Launch Support</h5>
                <p>
                  Comprehensive support from idea to launch, tailored to your
                  needs
                </p>
                <div className="arrow-button">
                  <span className="icon-ar-left"></span>
                </div>
              </div>
            </div>
          </div>

          <div className="card-holder">
            <div className="card">
              <div className="card-front">
                <h5>Community-Driven Fundraising</h5>
                <img
                  src={iconFundraising}
                  className="nav-logo"
                  title="Community-Driven Fundraising"
                  alt="Community-Driven Fundraising"
                />
                <div className="arrow-button">
                  <span className="icon-ar-right"></span>
                </div>
              </div>
              <div className="card-back">
                <h5>Community-Driven Fundraising</h5>
                <p>
                  Embrace the power of decentralized community-driven
                  fundraising
                </p>
                <div className="arrow-button">
                  <span className="icon-ar-left"></span>
                </div>
              </div>
            </div>
          </div>

          <div className="card-holder">
            <div className="card">
              <div className="card-front">
                <h5>Pre-Launch User Base</h5>
                <img
                  src={iconPrelaunch}
                  className="nav-logo"
                  title="Pre-Launch User Base"
                  alt="Pre-Launch User Base"
                />
                <div className="arrow-button">
                  <span className="icon-ar-right"></span>
                </div>
              </div>
              <div className="card-back">
                <h5>Pre-Launch User Base</h5>
                <p>
                  Access community from the get-go, fostering a substantial user
                  base pre-launch
                </p>
                <div className="arrow-button">
                  <span className="icon-ar-left"></span>
                </div>
              </div>
            </div>
          </div>

          <div className="card-holder">
            <div className="card">
              <div className="card-front">
                <h5>Active Avalanche Engagement</h5>
                <img
                  src={iconEngagement}
                  className="nav-logo"
                  title="Active Avalanche Engagement"
                  alt="Active Avalanche Engagement"
                />
                <div className="arrow-button">
                  <span className="icon-ar-right"></span>
                </div>
              </div>
              <div className="card-back">
                <h5>Active Avalanche Engagement</h5>
                <p>Engage with thousands of active participants on Avalanche</p>
                <div className="arrow-button">
                  <span className="icon-ar-left"></span>
                </div>
              </div>
            </div>
          </div>

          <div className="card-holder">
            <div className="card">
              <div className="card-front">
                <h5>Transparent & On-chain Fundraising</h5>
                <img
                  src={iconTransparent}
                  className="nav-logo"
                  title="Transparent & On-chain Fundraising"
                  alt="Transparent & On-chain Fundraising"
                />
                <div className="arrow-button">
                  <span className="icon-ar-right"></span>
                </div>
              </div>
              <div className="card-back">
                <h5>Transparent & On-chain Fundraising</h5>
                <p>Transparent on-chain fundraising data, easily accessible</p>
                <div className="arrow-button">
                  <span className="icon-ar-left"></span>
                </div>
              </div>
            </div>
          </div>

          <div className="card-holder">
            <div className="card">
              <div className="card-front">
                <h5>User-Friendly App</h5>
                <img
                  src={iconUser}
                  className="nav-logo"
                  title="User-Friendly App"
                  alt="User-Friendly App"
                />
                <div className="arrow-button">
                  <span className="icon-ar-right"></span>
                </div>
              </div>
              <div className="card-back">
                <h5>User-Friendly App</h5>
                <p>
                  Simplified fundraising through an intuitive, modern UX/UI
                  user-friendly app
                </p>
                <div className="arrow-button">
                  <span className="icon-ar-left"></span>
                </div>
              </div>
            </div>
          </div>

          <div className="card-holder">
            <div className="card">
              <div className="card-front">
                <h5>Compliance Measures in place</h5>
                <img
                  src={iconComplience}
                  className="nav-logo"
                  title="Compliance Measures in place"
                  alt="Compliance Measures in place"
                />
                <div className="arrow-button">
                  <span className="icon-ar-right"></span>
                </div>
              </div>
              <div className="card-back">
                <h5>Compliance Measures in place</h5>
                <p>
                  A serious legal framework equipped with the necessary
                  compliance measures
                </p>
                <div className="arrow-button">
                  <span className="icon-ar-left"></span>
                </div>
              </div>
            </div>
          </div>

          <div className="card-holder">
            <div className="card">
              <div className="card-front">
                <h5>Liquid Vesting Solution</h5>
                <img
                  src={iconLiquid}
                  className="nav-logo"
                  title="Liquid Vesting Solution"
                  alt="Liquid Vesting Solution"
                />
                <div className="arrow-button">
                  <span className="icon-ar-right"></span>
                </div>
              </div>
              <div className="card-back">
                <h5>Liquid Vesting Solution</h5>
                <p>Liquid Vesting in place to mitigate selling pressure</p>
                <div className="arrow-button">
                  <span className="icon-ar-left"></span>
                </div>
              </div>
            </div>
          </div>
        </Slider>
        <Link
          to="/projects"
          className="btn animate-elem animate-fadeIn animate-slideFromBottom p-relative animate-delay-06"
        >
          Learn more & Apply for Fundraise
        </Link>
      </section>

      <section className="team container d-flex f-align-center f-dir-col animate active">
        <div className="dash-div animate-elem animate-fadeIn animate-slideFromBottom p-relative animate-delay-02">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1157"
            height="2"
            viewBox="0 0 1157 2"
            fill="none"
          >
            <path
              d="M1 1H1156"
              stroke="#2F287B"
              strokeLinecap="round"
              strokeDasharray="2 10"
            />
          </svg>
        </div>
        <div className="d-flex f-align-center f-justify-between mobile-f-col">
          <div className="w-40 animate-elem animate-fadeIn animate-slideFromBottom p-relative animate-delay-04">
            <h2>TEAM</h2>
            <p>
              At the heart of our mission lies the pioneering of an innovative,
              fully on-chain decentralized seed fundraising technology,
              unlocking novel opportunities and fostering healthier grounds for
              projects. <br />
              <br /> By empowering the community as essential investors, we
              create a pathway to a bright future for both projects and users
              alike.
            </p>
          </div>
          <div className="w-50 team-container animate-elem animate-fadeIn animate-slideFromBottom p-relative animate-delay-06">
            <div className="team-member">
              <img src={elie} title="Elie LE REST" alt="Elie LE REST" />
              <div>
                <h5>Elie LE REST</h5>
                <p>Chief Executive Officer</p>
              </div>
            </div>
            <div className="team-member">
              <img src={wessal} title="WESSAL ER" alt="WESSAL ER" />
              <div>
                <h5>WESSAL ER</h5>
                <p>Chief Marketing Officer</p>
              </div>
            </div>
            <div className="team-member">
              <img
                src={gregorz}
                title="GRZEGORZ ŁYSKAWA"
                alt="GRZEGORZ ŁYSKAWA"
              />
              <div>
                <h5>GRZEGORZ ŁYSKAWA</h5>
                <p>Chief Technology Officer</p>
              </div>
            </div>
            <div className="team-member">
              <img src={aurelien} title="AURÉLIEN YNDEN" alt="AURÉLIEN YNDEN" />
              <div>
                <h5>AURÉLIEN YNDEN</h5>
                <p>Chief Operating Officer</p>
              </div>
            </div>
          </div>
        </div>
        <div className="support d-flex f-align-center f-dir-col animate-elem animate-fadeIn animate-slideFromBottom p-relative animate-delay-08">
          <h3 className="">
            <span className="underline">Proudly </span>Supported by
          </h3>
          <div className="partners">
            <Link
              to="https://www.avalabs.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={partner1} title="Aval Labs" alt="Aval Labs" />
            </Link>
            <Link
              to="https://shima.capital/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={partner2} title="Shima Capital" alt="Shima Capital" />
            </Link>
            <Link
              to="https://www.gbv.capital/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={partner3} title="GBV" alt="GBV" />
            </Link>
            <Link
              to="https://www.hashkey.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={partner4}
                title="Hashkey Capital"
                alt="Hashkey Capital"
              />
            </Link>
            <Link
              to="https://bixinvc.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={partner5} title="Bixin Ventures" alt="Bixin Ventures" />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
