//Colony Avalanche Index Page

import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import "../css/cai.css";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import ScrollButton from "../components/ScrollButton";

import clyCAI from "../lottie/CLY-Cai.json";
import iconExposure from "../images/icon-exposure.png";
import iconYield from "../images/icon-yield.png";
import iconImpartial from "../images/icon-impartial.png";
import iconIndex from "../images/icon-index.png";
import iconAutomated from "../images/icon-automated.png";
import iconInstant from "../images/icon-instant.png";
import iconCycle from "../images/icon-cycle.png";
import iconRebalancing from "../images/icon-rebalancing.png";
import logoCai from "../images/logo-Cai.png";
import logoPhuture from "../images/phuture.png";

function Cai() {
  // Lottie player settins
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768); // Adjust the breakpoint according to your design
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="content">
      <section className="cai container d-flex f-align-center">
        <div className="hero d-flex f-align-center mobile-f-col-rev">
          <div className="w-50 d-flex f-align-start f-dir-col">
            <h1 className="color-white mt-0 mb-0">Colony Avalanche Index</h1>
            <p className="text-large">
              The <span className="text-gradient">Colony Avalanche Index</span>{" "}
              is the benchmark index for the Avalanche ecosystem. $CAI tracks
              the performance of $AVAX and the top applications on Avalanche.
              Assets in the index are used productively to generate yield and
              improve returns.
            </p>
            <Link
              to="https://app.colonylab.io/index"
              target="_blank"
              rel="noopener noreferrer"
              className="btn mr-20"
            >
              Invest now
            </Link>
            <div className="cai-social">
              <p>Follow $CAI on social</p>
              <Link to="https://twitter.com/cai_index" target="_blank">
                <span className="icon-twitter"></span>
              </Link>
              <Link
                to="https://medium.com/cai-colony-avalanche-ecosystem-index"
                target="_blank"
              >
                <span className="icon-medium"></span>
              </Link>
            </div>
            <div className="partnership">
              <p>In partnership with</p>
              <Link to="https://www.phuture.finance/" target="_blank">
                <img src={logoPhuture} title="Phuture" alt="Phuture" />
              </Link>
            </div>
            <ScrollButton targetSectionId="section1" />
          </div>
          <div className="w-50 d-flex f-justify-center f-align-center">
            <Player autoplay={isDesktop} loop src={clyCAI}>
              <Controls visible={false} />
            </Player>
          </div>
        </div>
      </section>

      <section
        className="invest container d-flex f-align-center f-dir-col"
        id="section1"
      >
        <div>
          <h1 className="color-white mt-0 mb-0">Why Invest in CAI?</h1>
          <h3 className="text-gradient medium-title text-center">
            The largest Avalanche index
          </h3>
        </div>

        <div className="index-features">
          <div className="index-item">
            <div>
              <h5 className="color-white mt-0 mb-0">
                All-rounded exposure to Avalanche
              </h5>
              <p>
                CAI provides exposure to AVAX, as well as the Avalanche
                application layer, ensuring investors benefit from growth in the
                ecosystem as a whole.
              </p>
            </div>
            <div className="index-img">
              <img
                src={iconExposure}
                title="All-rounded exposure to Avalanche"
                alt="All-rounded exposure to Avalanche"
              />
            </div>
          </div>

          <div className="index-item">
            <div>
              <h5 className="color-white mt-0 mb-0">
                Additional returns from Yield Yak
              </h5>
              <p>
                We allocate a percentage of the tokens to unlock passive yield,
                so investors can benefit from additional returns.
              </p>
            </div>
            <div className="index-img">
              <img
                src={iconYield}
                title="Additional returns from Yield Yak"
                alt="Additional returns from Yield Yak"
              />
            </div>
          </div>

          <div className="index-item">
            <div>
              <h5 className="color-white mt-0 mb-0">
                Impartial, robust methodology
              </h5>
              <p>
                A full assessment of each constituent asset is transparently
                conducted.
              </p>
            </div>
            <div className="index-img">
              <img
                src={iconImpartial}
                title="Impartial, robust methodology"
                alt="Impartial, robust methodology"
              />
            </div>
          </div>

          <div className="index-item">
            <div>
              <h5 className="color-white mt-0 mb-0">
                The leading Avalanche index
              </h5>
              <p>
                CAI maintains deep liquidity so investors can enter and exit
                seamlessly. It is the largest Avalanche index to date, trusted
                by the top organizations in the Avalanche ecosystem.
              </p>
            </div>
            <div className="index-img">
              <img
                src={iconIndex}
                title="The leading Avalanche index"
                alt="The leading Avalanche index"
              />
            </div>
          </div>
        </div>
        <Link
          to="https://colonylab.gitbook.io/cai-colony-avalanche-index/ "
          target="_blank"
          rel="noopener noreferrer"
          className="btn"
        >
          View Documentation
        </Link>
      </section>

      <section className="management container d-flex f-align-center">
        <div className="container-wide d-flex f-align-center f-justify-center f-dir-col">
          <h2 className="text-center">
            World-class <span className="text-gradient">management</span>
          </h2>
          <div className="card-container d-flex">
            <div className="card-simple">
              <img
                src={iconAutomated}
                title="World-class management"
                className="small-icon"
                alt="World-class management"
              />
              <h5>Automated administration</h5>
              <p>
                Our protocol handles all index management from rebalancing, to
                allocating and tracking funds in yield bearing vaults,
                eliminating the risk of human error to safeguard your assets.
              </p>
            </div>

            <div className="card-simple">
              <img
                src={iconInstant}
                title="Instant redemptions"
                className="small-icon"
                alt="Instant redemptions"
              />
              <h5>Instant redemptions</h5>
              <p>
                24/7, immediately. Your funds are easily accessible whenever you
                need them.
              </p>
            </div>

            <div className="card-simple">
              <img
                src={iconCycle}
                title="Investment cycles"
                className="small-icon"
                alt="Investment cycles"
              />
              <h5>Investment cycles</h5>
              <p>
                24/7, immediately. You never have to wait for your money to be
                invested.
              </p>
            </div>

            <div className="card-simple">
              <img
                src={iconRebalancing}
                title="Rebalancing"
                className="small-icon"
                alt="Rebalancing"
              />
              <h5>Rebalancing</h5>
              <p>
                Occurs on the first business day of each month according to the
                methodology.
              </p>
            </div>
          </div>{" "}
          <div className="finer container-wide d-flex f-align-center">
            <div className="w-30">
              <h3 className="m-0">
                Finer <span className="text-gradient">details</span>
              </h3>
            </div>
            <div className="w-70 facts-container">
              <div className="facts">
                <div className="facts-label">Key facts</div>
                <div>
                  <strong>Inception date</strong>
                  <br />
                  <p>06/09/2022</p>
                </div>
                <div>
                  <strong>Strategy</strong>
                  <br />
                  <p>Capped circulating market cap</p>
                </div>
                <div>
                  <strong>Maximum capacity</strong>
                  <br />
                  <p>None</p>
                </div>
                <div>
                  <strong>Leverage</strong>
                  <br />
                  <p>None</p>
                </div>
              </div>
              <div className="facts">
                <div className="facts-label">Fees</div>
                <div>
                  <strong>Minimum investment</strong>
                  <br />
                  <p>None</p>
                </div>
                <div>
                  <strong>Entry fee</strong>
                  <br />
                  <p>0.2%</p>
                </div>
                <div>
                  <strong>Exit fee</strong>
                  <br />
                  <p>0.5%</p>
                </div>
                <div>
                  <strong>Management fee</strong>
                  <br />
                  <p>1%</p>
                </div>
              </div>
            </div>
          </div>
          <div className="cai-cta">
            <img
              src={logoCai}
              title="A Straightforward Hands-Off Passive Investment Strategy"
              className="cai-cta-img"
              alt="A Straightforward Hands-Off Passive Investment Strategy"
            />
            <h3 className="color-white mt-0 mb-0">
              A Straightforward Hands-Off Passive Investment Strategy
            </h3>
            <p className="text-large">
              CAI is the easiest and smartest way to gain exposure to the
              Avalanche ecosystem through one single token.
            </p>
            <Link
              to="https://app.colonylab.io/index"
              target="_blank"
              rel="noopener noreferrer"
              className="btn"
            >
              Invest now
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Cai;
