//Validator Page

import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";

import "../css/products.css";
import clyValidator from "../lottie/CLY-Validator.json";

function Validator() {
  // Lottie player settins
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768); // Adjust the breakpoint according to your design
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="content">
      <section className="validator container d-flex f-align-center">
        <div className="hero d-flex f-align-center mobile-f-col-rev">
          <div className="w-50 d-flex f-align-start f-dir-col">
            <h1 className="color-white mt-0 mb-0">Validator Program</h1>
            <h3 className="text-gradient medium-title">
              Earn AVAX rewards thanks to Colony's validator nodes
            </h3>
            <p>
              Colony's treasury includes an AVAX position, strategically
              utilized for running Avalanche nodes that enhance network
              security. By staking AVAX and operating these nodes, Colony gains
              AVAX rewards. A substantial majority of these rewards is directed
              back to the $CLY stakers community. In the forthcoming V2 of the
              Validator Program, it will also introduce liquid staking
              capabilities.
            </p>
            <Link
              to="https://app.colonylab.io/validator-program/avax "
              target="_blank"
              rel="noopener noreferrer"
              className="btn"
            >
              START EARNING AVAX REWARDS
            </Link>
          </div>
          <div className="w-50 d-flex f-justify-center f-align-center">
            <Player autoplay={isDesktop} loop src={clyValidator}>
              <Controls visible={false} />
            </Player>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Validator;
