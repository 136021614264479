// src/Footer.js

import { Link } from "react-router-dom";
import React from "react";
import "../css/footer.css";
import logoFooter from "../images/logo.svg";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer container" id="footer">
      <div className="footer-col">
        <img src={logoFooter} alt="Colony" className="logo-footer" />
        <p className="text-large">
          Decentralized Fundraising, Liquid Vesting, and an enticing array of
          yield-generating products. Your gateway to access the Avalanche
          ecosystem's growth through one inclusive platform. Empowering builders
          and communities alike.
        </p>
        <p>Copyright Colony @{currentYear}</p>
      </div>
      <div className="footer-col">
        <h4>Products</h4>
        <ul>
          <li>
            <Link to="/early-stage-program">
              Early-Stage Program & Liquid Vesting
            </Link>
          </li>
          <li>
            <Link to="/validator-program">Validator Program</Link>
          </li>
          <li>
            <Link to="/colony-avalanche-index">
              Colony Avalanche Index - $CAI{" "}
            </Link>
          </li>
          <li>
            <Link to="/liquidity-provision-program">
              Liquidity Provision Program
            </Link>
          </li>
        </ul>
        <h4>Exchanges</h4>
        <ul>
          <li>
            <Link
              to="https://traderjoexyz.com/avalanche/trade?inputCurrency=AVAX&outputCurrency=0xec3492a2508ddf4fdc0cd76f31f340b30d1793e6"
              target="_blank"
            >
              Trader Joe{" "}
            </Link>
          </li>
          <li>
            <Link to="https://www.mexc.com/price/CLY" target="_blank">
              MEXC
            </Link>
          </li>
        </ul>
      </div>
      <div className="footer-col">
        <h4>Discover</h4>
        <ul>
          <li>
            <Link to="https://docs.colonylab.io/" target="_blank">
              Documentation{" "}
            </Link>
          </li>
          <li>
            <Link
              to="https://colony-lab.notion.site/colony-lab/Colony-Job-Board-71d9ca89f81a406797c7d5384bf977ad"
              target="_blank"
            >
              Careers
            </Link>
          </li>
          <li>
            <Link
              to="https://drive.google.com/drive/folders/1ko1j8CFRcCPRRERHjMxwUuGv77UqeOau?usp=sharing"
              target="_blank"
            >
              Brand Assets
            </Link>
          </li>
        </ul>
        <h4>Contact</h4>
        <ul>
          <li>
            <Link
              to="https://forms.monday.com/forms/55c77c179ea135147b51cb5057cdab38?r=use1"
              target="_blank"
            >
              Apply for fundraise{" "}
            </Link>
          </li>
        </ul>
        <h4>Legal</h4>
        <ul>
          <li>
            <Link
              to="https://docs.google.com/document/d/1clYb-ow-AOJRQWbZ-5MY-gLLLVEF06F5/edit?usp=sharing&ouid=118308531784616582986&rtpof=true&sd=true"
              target="_blank"
            >
              Terms of Use{" "}
            </Link>
          </li>
          <li>
            <Link
              to="https://docs.google.com/document/d/1mzt7VKtGxENut56LKpnUbvbxzDGv8_f8/edit?usp=sharing&ouid=118308531784616582986&rtpof=true&sd=true"
              target="_blank"
            >
              Privacy Policy
            </Link>
          </li>
        </ul>
      </div>
      <div className="footer-col">
        <h4>Subscribe to the colony newsletter</h4>
        <form
          action="https://gmail.us6.list-manage.com/subscribe/post?u=4ba9e70a49abd61bca1ba67f3&amp;id=6ccc0fa402"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate=""
          data-np-checked="1"
        >
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_4ba9e70a49abd61bca1ba67f3_6ccc0fa402"
              tabIndex="-1"
              defaultValue=""
              data-np-checked="1"
            />
          </div>

          <div className="newsletter d-flex">
            <input
              type="email"
              defaultValue=""
              name="EMAIL"
              placeholder="satoshi@email.com"
            />
            <button type="submit" className="btn">
              Subscribe
            </button>
          </div>
        </form>
        <p className="privacy">
          By submitting this form, you agree to receive marketing and other
          communications from Colony about the Colony Products and other company
          updates. You can unsubscribe from these communications at any time.
          For more information on our privacy practices, please review our
          Privacy Policy.
        </p>

        <h4>Follow colony on social</h4>
        <div className="social">
          <Link to="https://twitter.com/Colonylab" target="_blank">
            <span className="icon-twitter"></span>
          </Link>
          <Link to="https://www.youtube.com/@ColonyLab" target="_blank">
            <span className="icon-youtube"></span>
          </Link>
          <Link
            to="https://www.linkedin.com/company/colonylab/"
            target="_blank"
          >
            <span className="icon-linkedin"></span>
          </Link>
          <Link to="https://medium.com/colony-lab" target="_blank">
            <span className="icon-medium"></span>
          </Link>
          <Link to="https://t.me/COLONY_Announcement" target="_blank">
            <span className="icon-telegram"></span>
          </Link>
          <Link to="https://bit.ly/colonylab-discord" target="_blank">
            <span className="icon-discord"></span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
