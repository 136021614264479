// Navigation

import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import "../css/navbar.css";
import logo from "../images/logo.svg";

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header className={menuOpen ? "open" : "close"}>
      <div className="container clearfix d-flex f-justify-between">
        <Link to="/" className="logo">
          <img src={logo} className="nav-logo" title="Colony" />
        </Link>
        <ul className="nav">
          <li className="has-children">
            <a>Products</a>
            <div>
              <ul id="submenu">
                <li>
                  <NavLink to="/early-stage-program" onClick={closeMenu}>
                    Early-Stage Program & Liquid Vesting
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/validator-program" onClick={closeMenu}>
                    Validator Program
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/colony-avalanche-index" onClick={closeMenu}>
                    Colony Avalanche Index - $CAI
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/liquidity-provision-program"
                    onClick={closeMenu}
                  >
                    Liquidity Provision Program
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <NavLink to="/community" onClick={closeMenu}>
              Community
            </NavLink>
          </li>
          <li>
            <NavLink to="/projects" onClick={closeMenu}>
              Projects
            </NavLink>
          </li>
          <Link
            to="https://app.colonylab.io/"
            className="btn"
            target="_blank"
            onClick={closeMenu}
          >
            Launch App
          </Link>
        </ul>
        <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </header>
  );
};
