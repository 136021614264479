import React from "react";
import loadImg from "../images/logo-mark.svg";

const Loading = () => {
  return (
    <div className="loading">
      <div className="load">
        {" "}
        <img src={loadImg} title="load" alt="load" />
      </div>
    </div>
  );
};

export default Loading;
